// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import 'firebase/auth'; // Import the authentication module if you're using authentication.
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBNBx6GNd8Q1C6q_Wb9AjAYDHIEehnrmZk",
  authDomain: "siddharth-315c3.firebaseapp.com",
  databaseURL: "https://siddharth-315c3.firebaseio.com",
  projectId: "siddharth-315c3",
  storageBucket: "siddharth-315c3.appspot.com",
  messagingSenderId: "27561250611",
  appId: "1:27561250611:web:b5bdc0950a71a52c26f846"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;