import React, { useState } from 'react';
import { Typography, Button, Paper, Grid, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, updateProfile } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { useUserContext } from '../helper/UserContext'; 
import { useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import { Merge } from '@mui/icons-material';

const FormContainer = styled(Grid)({
  marginTop: '3rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '70vh',
});

const SignInSignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [authSuccess, setAuthSuccess] = useState(false);
  const [authError, setAuthError] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();
  const { user, setUser } = useUserContext();

  const storeUserDataInFirestore = async (user) => {
    const userData = {
      uid: user.uid,
      displayName: user.displayName || '',
      email: user.email,
      photoURL: user.photoURL,
      // Add other user data fields as needed
    };

    try {
      const db = getFirestore();
      const userRef = doc(db, 'UserInfo', user.uid);
      await setDoc(userRef, userData, { merge: true });
      console.log('Data written to Firestore successfully');
    } catch (error) {
      console.error('Error writing data to Firestore:', error);
    }
  };

  const getUserDetailsFromFirestore = async (user) => {
    const db = getFirestore();
    const userRef = doc(db, 'UserInfo', user.uid);
    try {
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userDetails = userDoc.data();
        setUser(userDetails);
      }
      else{
        storeUserDataInFirestore(user);
      }
    } catch (error) {
      console.error('Error fetching user details from Firestore:', error);
    }
  };

  const handleAuth = async () => {
    try {
      if (isSignUp) {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        await getUserDetailsFromFirestore(user); // Fetch user details after sign-up
        setAuthSuccess(true);
      } else {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        await getUserDetailsFromFirestore(user); // Fetch user details after sign-in
        setAuthSuccess(true);
      }
    } catch (error) {
      setAuthError(error.message);
    }
  };

  const handleGoogleAuth = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      if (!user.displayName || !user.photoURL) {
        const googleUser = result.additionalUserInfo.profile;
        if (!user.displayName) {
          user.displayName = googleUser.name;
        }
        if (!user.photoURL) {
          user.photoURL = googleUser.picture;
        }

        await updateProfile(auth.currentUser, {
          displayName: user.displayName,
          photoURL: user.photoURL,
        });

      }

      await getUserDetailsFromFirestore(user); // Fetch user details after Google sign-in
      setAuthSuccess(true);
    } catch (error) {
      setAuthError(error.message);
    }
  };

  if (authSuccess) {
     navigate('/search');
     return null;
  }

  return (
    <FormContainer>
      <Paper elevation={3} style={{ padding: '2rem', width: '300px' }}>
        <Typography variant="h6">{isSignUp ? 'Sign Up' : 'Sign In'}</Typography>
        <TextField
          label="Email"
          variant="outlined"
          margin="normal"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          variant="outlined"
          margin="normal"
          fullWidth
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {authError && <div style={{ color: 'red' }}>{authError}</div>}
        <Button variant="contained" onClick={handleAuth} fullWidth>
          {isSignUp ? 'Sign Up' : 'Sign In'}
        </Button>
        <Button onClick={handleGoogleAuth} fullWidth>
          {isSignUp ? 'Sign Up with Google' : 'Sign In with Google'}
        </Button>
        <Divider style={{ margin: '1rem 0' }} />
        <Button onClick={() => setIsSignUp(!isSignUp)} fullWidth>
          {isSignUp ? 'Already have an account? Sign In' : "Don't have an account? Sign Up"}
        </Button>
      </Paper>
    </FormContainer>
  );
};

export default SignInSignUp;
