import React from 'react';
import { Typography } from '@mui/material';

// eslint-disable-next-line react/prop-types
const PageHeading = ({ children }) => {
  return (
    <Typography variant="h5" gutterBottom sx={{ marginTop: '2rem' }}>
      {children}
    </Typography>
  );
};

export default PageHeading;
