import React, { createContext, useContext, useEffect, useState } from 'react';
import {
    getAuth
  } from 'firebase/auth';
const FirebaseContext = createContext();
const auth = getAuth();

export function useFirebase() {
  return useContext(FirebaseContext);
}

// eslint-disable-next-line react/prop-types
export function FirebaseProvider({ children }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  return (
    <FirebaseContext.Provider value={{ user }}>
      {children}
    </FirebaseContext.Provider>
  );
}

