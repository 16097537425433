/* eslint-disable react/jsx-no-undef */
// MenuBar.js
import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Box
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { getFirestore, collection, doc, getDoc } from 'firebase/firestore';
import { useFirebase } from '../firebaseContext';
import logoImage from '../images/logoMentors.png';

function MenuBar() {
  const auth = getAuth();
  const { user } = useFirebase();
  const [anchorEl, setAnchorEl] = useState(null);
  const [photoURL, setphotoURL] = useState(null);
  const navigate = useNavigate();
  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Sign out error', error);
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileDetails = () => {
    navigate("/profile-edit");
    handleProfileMenuClose();
  };

  useEffect(() => {
    if (user) {
      const db = getFirestore();
      const userDocRef = doc(db, 'UserInfo', user.uid);

      getDoc(userDocRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            const userData = docSnap.data();
            if (userData.photoURL) {
              setphotoURL(userData.photoURL);
            }
          }
        })
        .catch((error) => {
          console.error('Error fetching profile image URL:', error);
        });
    }
  }, [user]);

  return (
    <AppBar position="static">
      <Toolbar style={{ justifyContent: 'space-between'}}>
      <Button component={Link} to="/">
      <Box
        component="img"
        sx={{ height: 70 }}
        alt="Logo"
        src={logoImage}
      />
      </Button>
        <div>
          <Button component={Link} to="/" color="inherit">
            Home
          </Button>
          <Button component={Link} to="/search" color="inherit">
            Search
          </Button>
          {user ? (
            <>
              <IconButton
                color="inherit"
                aria-controls="profile-menu"
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
              >
                {photoURL ? (
                  <Avatar alt="Profile" src={photoURL} />
                ) : (
                  <Avatar alt="Profile" />
                )}
              </IconButton>
              <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleProfileMenuClose}
              >
                <MenuItem onClick={handleProfileDetails}>Profile Details</MenuItem>
                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
              </Menu>
            </>
          ) : (
            <Button component={Link} to="/SignIn" color="inherit">
              Sign In
            </Button>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default MenuBar;
