import React, { useState } from 'react';
import { TextField, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

// eslint-disable-next-line react/prop-types
const SearchBar = ({ onSearch }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = () => {
        onSearch(searchTerm);
    };

    return (
        <div>
            <TextField
                variant="outlined"
                fullWidth
                value={searchTerm}
                placeholder="Type your Role, Domain, Industry, etc..."
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        handleSearch();
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <IconButton color="primary" onClick={handleSearch}>
                            <SearchIcon />
                        </IconButton>
                    ),
                }}
            />
        </div>
    );
};

export default SearchBar;
