
import React from 'react';
import { Container, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system'; // Import styled from @mui/system
import theme from '../theme';


const Feature = styled('div')({
    padding: theme.spacing(3),
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: '12px',
    cursor: 'pointer',
    transition: 'transform 0.2s ease-in-out',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  });
const handleFeatureClick = () => {
   
    console.log('Feature clicked');
  };
function Features() {
    return (
        <section>
        <Container maxWidth="md">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Feature onClick={handleFeatureClick}>
                <Typography variant="h5">Expert Guidance</Typography>
                <Typography variant="body1">
                  Access advice from industry experts.
                </Typography>
              </Feature>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Feature onClick={handleFeatureClick}>
                <Typography variant="h5">Personalized Career Paths</Typography>
                <Typography variant="body1">
                  Discover the best career path for you.
                </Typography>
              </Feature>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Feature onClick={handleFeatureClick}>
                <Typography variant="h5">Resources & Tools</Typography>
                <Typography variant="body1">
                  Access a wealth of career resources and tools.
                </Typography>
              </Feature>
            </Grid>
          </Grid>
        </Container>
      </section>
    )
}
export default Features;