import React from 'react';
import { Container, Typography, Button, Box, Grid, Link } from '@mui/material';
import { styled } from '@mui/system';
import theme from '../theme';
import Features from './features';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from React Router
import { useFirebase } from '../firebaseContext';
import SearchBar from './searchBar';

const HeroSection = styled('section')({
  background: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  color: theme.palette.common.white,
  padding: theme.spacing(8),
  textAlign: 'center',
});

const Footer = styled('footer')({
  background: `#333`,
  color: theme.palette.common.white,
  padding: theme.spacing(1),
  textAlign: 'center',
  position: 'fixed', // Use 'fixed' to keep it at the bottom
  bottom: 0,
  left: 0, // Adjust left and right to control width
  right: 0,
  width: '100%', // Make it span the entire width
});



const CtaButton = styled(Button)({
  marginTop: theme.spacing(2),
});

function HomePage() {
  const navigate = useNavigate();
  const { user } = useFirebase();
  const handleGetStarted = () => {
    user ? navigate('/search') : navigate('/SignIn');
  };

  const handleSearch = (searchTerm) => {
    navigate(`/search/${searchTerm}`);
};

  return (
    <div>
      {/* Hero Section */}
      <HeroSection>
        <Container maxWidth="md">
          <Typography variant="h4" gutterBottom>
            WELCOME TO THEMENTORSAPP.COM
          </Typography>
          <Typography variant="body1" paragraph>
            Your guide to career success
          </Typography>
          <SearchBar onSearch={handleSearch} isBlack={false} />
        </Container>
      </HeroSection>

      {/* Features Section */}
      <Features></Features>

      {/* Footer */}
      <Footer>
        <Container maxWidth="md">
          <Typography variant="body2">
            &copy; {new Date().getFullYear()} GetCareerMentor.com. All rights reserved.
          </Typography>
          <Box mt={2}>
            <Link href="/privacy-policy" color="inherit">
              Privacy Policy
            </Link>
            <Box component="span" mx={2}>|</Box>
            <Link href="/terms-of-service" color="inherit">
              Terms of Service
            </Link>
          </Box>
        </Container>
      </Footer>
    </div>
  );
}

export default HomePage;
