import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    Grid,
    Container,
} from '@mui/material';
import PageHeading from './PageHeading';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { useFirebase } from '../firebaseContext';
import { useNavigate } from 'react-router-dom';

const ProfileDetailsForm = () => {
    const { user } = useFirebase();
    const navigate = useNavigate();
    const [enableMentor, setenableMentor] = useState(false);

    useEffect(() => {
        if (user) {
            const db = getFirestore();
            const userDocRef = doc(db, 'UserInfo', user.uid);

            getDoc(userDocRef)
                .then((docSnap) => {
                    if (docSnap.exists()) {
                        const userData = docSnap.data();
                        console.log(userData)
                        setFormData({
                            fullName: userData.displayName || '',
                            dateOfBirth: userData.dateOfBirth || '',
                            professionalExperience: userData.professionalExperience || '',
                            domain: userData.domain || '',
                            company: userData.company || '',
                            jobTitle: userData.jobTitle || '',
                            location: userData.location || '',
                            bio: userData.bio || '',
                            isMentor: userData.isMentor || false
                        });
                    }
                })
                .catch((error) => {
                    console.error('Error fetching profile details:', error);
                });
        }
    }, [user]);

    const [formData, setFormData] = useState({
        fullName: '',
        dateOfBirth: '',
        professionalExperience: '',
        domain: '',
        company: '',
        jobTitle: '',
        location: '',
        bio: '',
        isMentor: false, // Initialize isMentor field
    });

    const handleChange = (e) => {
        if (e.target.name == "professionalExperience" && e.target.value >= 10) {
            setenableMentor(true);
        }
        else {
            setenableMentor(false);
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleBecomeMentor = async () => {
        if (!user) {
            // Handle the case where the user is not logged in.
            return;
        }

        try {
            const db = getFirestore();
            const userRef = doc(db, 'UserInfo', user.uid);

            // Update the isMentor field to true
            const dataToSave = {
                isMentor: true,
            };

            await setDoc(userRef, dataToSave, { merge: true }); // Use 'merge: true' to update the existing document

            // Update the local formData to reflect the change
            setFormData({ ...formData, isMentor: true });

            console.log('User has become a mentor');
        } catch (error) {
            console.error('Error updating mentor status:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!user) {
            // Handle the case where the user is not logged in.
            return;
        }

        try {
            const db = getFirestore();
            const userRef = doc(db, 'UserInfo', user.uid);

            const dataToSave = {
                displayName: formData.fullName || '',
                dateOfBirth: formData.dateOfBirth || '',
                professionalExperience: formData.professionalExperience || '',
                domain: formData.domain || '',
                company: formData.company || '',
                jobTitle: formData.jobTitle || '',
                location: formData.location || '',
                bio: formData.bio || ''
            };

            await setDoc(userRef, dataToSave, { merge: true }); // Use 'merge: true' to update the existing document

            console.log('Data saved to Firestore successfully');
            navigate('/search');
        } catch (error) {
            console.error('Error saving data to Firestore:', error);
        }
    };

    return (
        <Container>
            <PageHeading>Profile Details</PageHeading>
            <form onSubmit={handleSubmit} className="profile-details-form">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            name="fullName"
                            label="Full Name"
                            variant="outlined"
                            fullWidth
                            value={formData.fullName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="dateOfBirth"
                            label="Date of Birth"
                            type="date"
                            variant="outlined"
                            fullWidth
                            value={formData.dateOfBirth}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="professionalExperience"
                            label="Professional Experience (years)"
                            type="number"
                            variant="outlined"
                            fullWidth
                            value={formData.professionalExperience}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="domain"
                            label="Domain"
                            variant="outlined"
                            fullWidth
                            value={formData.domain}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="company"
                            label="Company"
                            variant="outlined"
                            fullWidth
                            value={formData.company}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="jobTitle"
                            label="Job Title"
                            variant="outlined"
                            fullWidth
                            value={formData.jobTitle}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="location"
                            label="Location"
                            variant="outlined"
                            fullWidth
                            value={formData.location}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="bio"
                            label="Bio"
                            variant="outlined"
                            fullWidth
                            multiline // Enable multiline input
                            minRows={4} // Adjust the number of rows as needed
                            value={formData.bio}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item container xs={12} spacing={2}>
                        <Grid item xs={1}>
                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                Save
                            </Button>
                        </Grid>
                        {
                            enableMentor ?
                                <Grid item xs={3}>
                                    <div style={{ margin: '0 8px' }}>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            onClick={handleBecomeMentor}
                                            disabled={formData.isMentor}
                                            fullWidth
                                        >
                                            {formData.isMentor ? 'You are a mentor' : 'Become a Mentor'}
                                        </Button>
                                    </div>
                                </Grid>
                                :
                                <Grid item xs={3}>
                                    <div style={{ margin: '0 8px' }}>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            disabled={true}
                                            fullWidth
                                        >
                                            {formData.isMentor ? 'You are a mentor' : 'Become a Mentor'}
                                        </Button>
                                    </div>
                                </Grid>
                        }

                    </Grid>

                </Grid>
            </form>
        </Container>
    );
};

export default ProfileDetailsForm;
