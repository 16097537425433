import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  Avatar,
  Paper,
  Box,
} from '@mui/material';
import SearchBar from './searchBar';
import PageHeading from './PageHeading';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { useFirebase } from '../firebaseContext';


// eslint-disable-next-line no-undef
const algoliasearch = require('algoliasearch/lite');
const searchClient = algoliasearch('5CKDHSOR9U', '55bae2ca909d5b35f6bf2eebe4e0cfc9');
const index = searchClient.initIndex('displayName');

const MentorCard = styled(Paper)({
  padding: '16px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  borderRadius: '4px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  background: 'linear-gradient(to right, #F5F7FA, #F9FAFB)',
});

const AvatarImage = styled(Avatar)({
  width: '80px',
  height: '80px',
  borderRadius: '50%',
  border: '1px solid #E6E8EB',
});
const MentorSearch = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { value } = useParams();
  const { user } = useFirebase();


  useEffect(() => {
    setSearchTerm(value || null); // Set the initial search term
  }, [value]);

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const { hits } = await index.search(searchTerm, {
                hitsPerPage: 5,
                filters: `NOT uid:${user.uid}`, // Exclude current user
            });
        setSearchResults(hits);
      } catch (error) {
        console.error(error);
      }
    };

    // Call the function
    fetchSearchResults();
  }, [searchTerm]);

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
  };

  return (
    <Container>
      <PageHeading>Find a Mentor</PageHeading>
      <SearchBar onSearch={handleSearch} isBlack={true} />
      <Grid container spacing={2}>
        {searchResults.map((mentor, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <MentorCard elevation={3}>
                <AvatarImage alt={mentor.displayName} src={mentor.photoURL || 'placeholder.jpg'} />
              </MentorCard>
              <Box
                sx={{
                  width: '100%',
                  padding: '16px',
                  backgroundColor: '#F0F0F0', // Add background color
                  borderRadius: '4px',
                }}
              >
                <Typography variant="h6">{mentor.displayName}</Typography>
                <Typography variant="body1">{mentor.domain}</Typography>
                <Typography variant="body2">Job Title: {mentor.jobTitle}</Typography>
                <Typography variant="body2">Professional Experience: {mentor.professionalExperience} years</Typography>
                <Typography variant="body2">Bio: {mentor.bio}</Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default MentorSearch;
