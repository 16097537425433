// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/homepage';
import MentorsSearch from './components/mentorsSearch';
import MenuBar from './components/menuBar';
import SignInSignUp from './components/signin';
import { FirebaseProvider } from './firebaseContext';
import ProfileDetailsForm from './components/profiledetailsform';
import { UserProvider } from './helper/UserContext'; 

function App() {
  return (
    <FirebaseProvider>
      <UserProvider> 
        <Router>
          <div>
            <MenuBar />
            <main className="main-content">
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/profile-edit" element={<ProfileDetailsForm />} />
                <Route path="/search" element={<MentorsSearch />} />
                <Route path="/search/:value" element={<MentorsSearch/>}/>
                <Route path="/SignIn" element={<SignInSignUp />} />
              </Routes>
            </main>
          </div>
        </Router>
      </UserProvider>
    </FirebaseProvider>
  );
}

export default App;
